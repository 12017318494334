<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FED159;"
      d="M336.619,298.794v96.86c0,14.283-11.571,25.866-25.866,25.866H25.866
	C11.583,421.52,0,409.937,0,395.654V237.069c0-14.283,11.583-25.866,25.866-25.866h59.995L336.619,298.794z"
    />
    <rect
      x="0.004"
      y="342.044"
      style="fill:#65B4BB;"
      width="336.619"
      height="33.896"
    />
    <path
      style="fill:#F6C454;"
      d="M336.619,298.794v19.449H111.353c0,0.012,0,0.012,0,0.012H66.412V211.203h19.449L336.619,298.794z"
    />
    <rect
      x="85.873"
      y="90.48"
      style="fill:#82DCC7;"
      width="426.127"
      height="208.306"
    />
    <g>
      <path
        style="fill:#74CBB4;"
        d="M130.808,298.796h-44.94v-44.94C110.686,253.856,130.808,273.968,130.808,298.796z"
      />
      <path
        style="fill:#74CBB4;"
        d="M85.868,90.488h44.94c0,24.817-20.122,44.94-44.94,44.94V90.488z"
      />
      <path
        style="fill:#74CBB4;"
        d="M512,90.488v44.94c-24.828,0-44.94-20.122-44.94-44.94C467.06,90.488,512,90.488,512,90.488z"
      />
      <path
        style="fill:#74CBB4;"
        d="M512,253.856v44.94h-44.94C467.061,273.968,487.173,253.856,512,253.856z"
      />
      <ellipse
        style="fill:#74CBB4;"
        cx="298.936"
        cy="194.633"
        rx="56.571"
        ry="71.38"
      />
      <rect
        x="383.524"
        y="175.605"
        style="fill:#74CBB4;"
        width="102.564"
        height="15.861"
      />
      <rect
        x="107.543"
        y="175.605"
        style="fill:#74CBB4;"
        width="102.564"
        height="15.861"
      />
      <rect
        x="422.656"
        y="205.48"
        style="fill:#74CBB4;"
        width="63.443"
        height="15.861"
      />
      <rect
        x="107.543"
        y="205.48"
        style="fill:#74CBB4;"
        width="63.443"
        height="15.861"
      />
    </g>
    <polygon
      style="fill:#82DCC7;"
      points="291.004,171.785 306.866,171.785 332.248,171.785 332.248,155.924 306.866,155.924 
	306.866,138.335 291.004,138.335 291.004,155.924 265.622,155.924 265.622,202.568 316.387,202.568 316.387,217.49 306.866,217.49 
	291.004,217.49 265.622,217.49 265.622,233.352 291.004,233.352 291.004,250.942 306.866,250.942 306.866,233.352 332.248,233.352 
	332.248,186.707 281.483,186.707 281.483,171.785 "
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
